import React from "react"

class Newsletter extends React.Component {
  render() {
    return (
      <div>
        <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" />
        <div id="mc_embed_signup">
          <form action="https://derrickpersson.us17.list-manage.com/subscribe/post?u=1aadc88bc392f336d7927a4f1&amp;id=f06f0d1071" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <label for="mce-EMAIL">Subscribe</label>
              <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required />
              <input style={{ visibility: 'hidden' }} type="hidden" name="SOURCE" value="blog" />
              <div style={{ 
                  position: 'absolute', 
                  left: '-5000px' 
                  }} 
                  aria-hidden="true"><input type="text" name="b_1aadc88bc392f336d7927a4f1_f06f0d1071" tabindex="-1" /></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" /></div>
              </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Newsletter
